import React from 'react'
import { Layout } from '@/components/lp/scalehack/for-marketing/layout'
import { Meta } from '@/components/lp/scalehack/for-marketing/meta'
import { Header } from '@/components/lp/scalehack/for-marketing/company/header'
import { Contact } from '@/components/lp/scalehack/for-marketing/company/contact'

export default () => {
  return (
    <>
      <Layout>
        <Meta
          title="無料登録 | Scalehack for Marketing :ハイクラス人材が提供するマーケティング支援"
          description='Scalehack for Marketingは、"本物"のハイクラス人材が提供する的確で効率的なマーケティング支援サービスです。中途採用では採れない実務経験豊富なマーケティングプロフェッショナルが、事業フェーズや課題に合わせて的確なマーケティング支援を致します。'
          script={['https://sdk.form.run/js/v2/formrun.js']}
          ogImg="https://relic-holdings.co.jp/OGP_1200*630_ScaleHack_Marketing_Corporate.png"
        />
        <Header />
        <Contact />
      </Layout>
    </>
  )
}
